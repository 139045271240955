<template>
  <el-dialog :title="title"
             :visible.sync="outerVisible"
             @close="close"
             width="70%">

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="회원정보1" name="userInfo" style="max-height: 600px;overflow-y: scroll">
        <table class="table100"
               style="margin-bottom: 10px" v-if="mode === managerConst.MODE_EDIT">

          <tr>
            <th colspan="2">당일 총입금금액</th>
            <th colspan="2">당일 입금회수</th>
            <th colspan="2">당일 총출금금액</th>
            <th colspan="2">당일 출금회수</th>
            <th></th>
          </tr>
          <tr>
            <td colspan="2" style="color: navy">+ {{ user.todayRechargeTotal|comma }}</td>
            <td colspan="2">{{ user.todayRechargeCount }} 회</td>
            <td colspan="2" style="color: red">- {{ user.todayExchangeTotal|comma }}</td>
            <td colspan="2">{{ user.todayExchangeCount }} 회</td>
            <td></td>
          </tr>
          <tr>
            <th colspan="9">예금주 동일명 가입 회원정보</th>
          </tr>
          <tr>
            <th style="width: 10%">닉네임</th>
            <th style="width: 10%">예금주</th>
            <th style="width: 10%">은행</th>
            <th style="width: 10%">계좌번호</th>
            <th style="width: 10%">생일</th>
            <th style="width: 10%">통신사</th>
            <th style="width: 10%">폰번호</th>
            <th style="width: 10%">가입IP</th>
            <th style="width: 10%">최근로그인IP</th>
          </tr>
          <tr v-for="bu in user.eqBeneficiaryUsers" v-if="bu.id != user.id">
            <td>{{ bu.nickname }}</td>
            <td>{{ bu.beneficiary }}</td>
            <td>{{ bu.bank }}</td>
            <td>{{ bu.acNo }}</td>
            <td :class="{'text-red':bu.issambirthday == managerConst.YES}">
              {{ bu.byear }}-{{ bu.bmonth }}-{{ bu.bday }}
            </td>
            <td>{{ bu.telcomp }}</td>
            <td>{{ bu.phone }}</td>
            <td>{{ bu.regip }}</td>
            <td>{{ bu.lastLoginIp }}</td>
          </tr>
        </table>
        <table class="table_userstatisc" v-if="mode === managerConst.MODE_EDIT">
          <tr>
            <th>번호/아이디</th>
            <th>추천코드</th>
            <th>보유머니</th>
            <th>보유포인트</th>
            <th>카지노머니</th>
            <th>총입금</th>
            <th>총출금</th>
            <th>손이익</th>
          </tr>
          <tr>
            <td>

              {{user.id}}/{{ user.username }}
            </td>
            <td>{{ user.code }}</td>
            <td>{{ user.cash|comma }}</td>
            <td>{{ user.userPoint|comma }}</td>
            <td>{{ user.casinocash|comma }}
              <el-button size="mini" @click="subjectCasionMoney(user.id)">강제전환</el-button>
            </td>
            <td>{{ user.totalrecharge | comma }}</td>
            <td>{{ user.totalexchange | comma }}</td>
            <td>
              <font style="color: red" v-if="user.totalrecharge - user.totalexchange < 0">
                {{ (user.totalrecharge - user.totalexchange) | comma }}</font>
              <font style="" v-if="user.totalrecharge - user.totalexchange >= 0">
                {{ (user.totalrecharge - user.totalexchange) | comma }}</font>

            </td>
          </tr>
          <tr>
            <th>가입일짜</th>
            <th colspan="2">가입IP</th>
            <th colspan="2">최근로그인일짜</th>
            <th colspan="2">최근로그인IP</th>
            <th></th>
          </tr>
          <tr>
            <td>{{ user.createTime | datef('yyyy-MM-DD HH:mm') }}</td>
            <td colspan="2">{{ user.regip }}</td>
            <td colspan="2">{{ user.lastLoginTime | datef('yyyy-MM-DD HH:mm') }}</td>
            <td colspan="2">{{ user.lastLoginIp }}</td>
            <td>
              <el-button size="mini" @click="removeUserSession(user.username)">강제로그아웃</el-button>
            </td>
          </tr>
        </table>
        <table class="table_userstatisc" v-if="null != userStatistic && mode === managerConst.MODE_EDIT">
          <tr>
            <th>유형</th>
            <th>베팅건수</th>
            <th>베팅금액</th>
            <th>베팅당첨금액</th>
            <th>손이익</th>
          </tr>
          <tr>
            <td>스포츠</td>
            <td>{{ userStatistic.betCount | comma }} 건</td>
            <td>{{ userStatistic.betCashTotal | comma }} 원</td>
            <td>{{ userStatistic.winCashTotal | comma }}&nbsp;원</td>
            <td>{{ (userStatistic.betCashTotal - userStatistic.winCashTotal) | comma }}&nbsp;원</td>
          </tr>
          <tr>
            <td>미니게임</td>
            <td>{{ userStatistic.betLeisureCount | comma }}&nbsp;건</td>
            <td>{{ userStatistic.betLeisureCashTotal | comma }}&nbsp;원</td>
            <td>{{ userStatistic.winLeisureCashTotal | comma }}&nbsp;원</td>
            <td>{{ (userStatistic.betLeisureCashTotal - userStatistic.winLeisureCashTotal) | comma }}&nbsp;원
            </td>
          </tr>
          <tr>
            <th colspan="5">최근 입금기준 롤링 프로수</th>
          </tr>
          <tr>
            <td colspan="5">{{ user.rollingInfo }}</td>
          </tr>
        </table>
        <table class="table100">
          <tr>
            <th colspan="2" style="text-align: center">
              아이디
              <span v-if="mode === managerConst.MODE_EDIT">
                /아널ID/코인ID
              </span>
            </th>
            <th colspan="3" style="text-align: center">머니,포인트 추가 or 차감</th>
          </tr>
          <tr>
            <td colspan="2">
              <el-input size="mini" v-model="user.username"
                        v-if="mode === managerConst.MODE_SAVE"></el-input>
              <span v-if="mode === managerConst.MODE_EDIT">
                {{ user.username }} /<font style="color: grey">{{ user.honoruid }}</font>/<font style="color: grey">{{user.coinaccount}}</font>
              </span>
            </td>
            <td colspan="3">
                            <span v-if="mode === managerConst.MODE_EDIT">
                                 <el-select size="mini" v-model="user.cpType" placeholder="머니 or 포인트"
                                            style="width: 100px">
                                <el-option value="1" label="회원머니">회원머니</el-option>
                                <el-option value="2" label="회원포인트">회원포인트</el-option>
                            </el-select>
                            <el-select size="mini" v-model="user.flowType" placeholder="추가 or 차감"
                                       style="width: 100px;margin-left: 3px;">
                                <el-option value="1" label="추가">추가(+)</el-option>
                                <el-option value="2" label="차감">차감(-)</el-option>
                            </el-select>
                            <el-input size="mini" type="number" placeholder="수량" v-model="user.flowCash"
                                      style="width: 100px;margin-left: 3px;"></el-input>
                            <el-input size="mini" placeholder="머니,포인트 메모" v-model="user.memo"
                                      style="width: 300px;margin-left: 3px;"></el-input>
                            <el-button @click="save" type="primary" size="mini" style="margin-left: 10px">확인</el-button>
                            </span>

            </td>
          </tr>
          <tr>
            <th>닉네임</th>
            <th>비밀번호</th>
            <th>통신사/휴대폰</th>
            <th>예금주</th>
            <th>통계여부</th>
          </tr>
          <tr>
            <td>
              <el-input size="mini" clearable v-model="user.nickname"></el-input>
            </td>
            <td>
              <el-input size="mini" clearable v-model="user.passwd"></el-input>
            </td>
            <td>
              <el-select size="mini" v-model="user.telcomp" placeholder="통신사" style="width: 40%;">
                <el-option :value="null" label="통신사선택">통신사선택</el-option>
                <el-option value="SKT">SKT</el-option>
                <el-option value="LG">LG</el-option>
                <el-option value="KT">KT</el-option>
                <el-option value="알뜰SKT">알뜰SKT</el-option>
                <el-option value="알뜰LG">알뜰LG</el-option>
                <el-option value="알뜰KT">알뜰KT</el-option>
              </el-select>
              <el-input size="mini" clearable v-model="user.phone"
                        style="width: 49%;margin-left: 5px"></el-input>
            </td>
            <td>
              <el-input size="mini" clearable v-model="user.beneficiary"></el-input>
            </td>
            <td>
              <el-select size="mini" v-model="user.betCashStatisticable" placeholder="통계집계 선택">
                <el-option :value="managerConst.YES" label="예">예</el-option>
                <el-option :value="managerConst.NOT" label="아니오">아니오</el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <th>은행명</th>
            <th>계좌번호</th>
            <th>생일 년/월/일</th>
            <th>레벨</th>
            <th>총판</th>
          </tr>
          <tr>
            <td>
<!--              <el-input size="mini" clearable v-model="user.bank"></el-input>-->
              <select style="font-size: 13px;padding: 3px 0;width: 95%;text-align: center" v-model="user.bank">
                <option value="">- - - 은행선택 - - -</option>
                <option value="카카오뱅크*11">카카오뱅크*11</option>
                <option value="케이뱅크*10">케이뱅크*10</option>
                <option value="토스뱅크*24">토스뱅크*24</option>
                <option value="신한*2">신한은행*2</option>
                <option value="국민*0">국민은행*0</option>
                <option value="농협중앙*7">농협중앙*7</option>
                <option value="단위농협*8">단위농협*8</option>
                <option value="우리*1">우리은행*1</option>
                <option value="하나*3">하나은행*3</option>
                <option value="기업*6">기업은행*6</option>
                <option value="우체국*22">우체국*22</option>
                <option value="대구*19">대구은행*19</option>
                <option value="경남*17">경남은행*17</option>
                <option value="광주*18">광주은행*18</option>
                <option value="부산*16">부산은행*16</option>
                <option value="신협*13">신협은행*13</option>
                <option value="전북*20">전북은행*20</option>
                <option value="제주*21">제주은행*21</option>
                <option value="씨티*5">씨티은행*5</option>
                <option value="새마을*9">새마을금고*9</option>
                <option value="SC제일은행*4">SC제일은행*4</option>
                <option value="산림조합*14">산림조합*14</option>
                <option value="수협*12">수협*12"</option>
                <option value="상호저축은행*15">상호저축은행*15</option>
                <option value="신한금융투자*43">신한금융투자*43</option>
                <option value="하나투자증권*23">하나투자증권*23</option>
              </select>
            </td>
            <td>
              <el-input size="mini" clearable v-model="user.acNo"></el-input>
            </td>
            <td style="display: flex;align-items: center;justify-content: space-between">
              <el-input size="mini" clearable v-model="user.byear" placeholder="년"></el-input>
              <el-input size="mini" clearable v-model="user.bmonth" placeholder="월"></el-input>
              <el-input size="mini" clearable v-model="user.bday" placeholder="일"></el-input>
<!--              <el-select size="mini" style="font-size: 13px;padding: 3px 0;width: 80px"-->
<!--                         v-model="user.byear">-->
<!--                <el-option :value="null">=년도=</el-option>-->
<!--                <el-option :value="item" v-for="item in syear">{{ item }}</el-option>-->
<!--              </el-select>-->
<!--              <el-select size="mini" style="font-size: 13px;padding: 3px 0;margin: 0 1px;width: 70px"-->
<!--                         v-model="user.bmonth">-->
<!--                <el-option :value="null">=월=</el-option>-->
<!--                <el-option :value="item" v-for="item in smonth">{{ item }}</el-option>-->
<!--              </el-select>-->
<!--              <el-select size="mini" style="font-size: 13px;padding: 3px 0;width: 70px"-->
<!--                         v-model="user.bday">-->
<!--                <el-option :value="null">=일=</el-option>-->
<!--                <el-option :value="item" v-for="item in sday">{{ item }}</el-option>-->
<!--              </el-select>-->
            </td>
            <td>
              <!--레벨-->
              <el-select size="mini" v-model="user.rankId" placeholder="레벨 선택">
                <el-option v-for="rank in rankList" :value="rank.id" :label="rank.rankName">
                  {{ rank.rankName }}
                </el-option>
              </el-select>
            </td>
            <td>
              <el-select v-model="user.partnerId" size="mini" placeholder="파트너 선택">
                <el-option
                    v-for="item in agentList"
                    :key="item.id"
                    :label="'lv'+item.level + ' > '+item.nickname"
                    :value="item.id">
                </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <th>회원타입</th>
            <th>회원상태</th>
            <th>그룹</th>
            <th>특별관리</th>
            <th>인플레이 단폴 제한횟수</th>
          </tr>
          <tr>
            <td>
              <el-select size="mini" v-model="user.utype" placeholder="회원타입 선택">
                <el-option :value="managerConst.User.USER_TYPE_NOMAL" label="일반회원">일반회원</el-option>
                <el-option :value="managerConst.User.USER_TYPE_FACK" label="가라회원">가라회원</el-option>
                <el-option :value="managerConst.User.USER_TYPE_MANAGER" label="관리자회원">관리자회원</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="user.status" placeholder="회원상태 선택">
                <el-option :value="managerConst.User.STATUS_ENABLE" label="정상">정상</el-option>
                <el-option :value="managerConst.User.STATUS_DISABLE" label="블럭">블럭</el-option>
                <el-option :value="managerConst.User.STATUS_UNAUTHORIZED" label="미인증">미인증</el-option>
                <el-option :value="managerConst.User.STATUS_AUTHORIZING" label="인증중">인증중</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="user.groupId" placeholder="그룹 선택">
                <el-option v-for="group in groupList" :value="group.id" :label="group.groupName">
                  {{ group.groupName }}
                </el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="user.risk" placeholder="특별관리 선택">
                <el-option :value="managerConst.NOT" label="아니오">아니오</el-option>
                <el-option :value="managerConst.YES" label="예">예</el-option>
              </el-select>
            </td>
            <td>
              <el-input size="mini" v-model="user.inplay1betlimit"></el-input>
            </td>
          </tr>
          <tr>
            <th>고객센터</th>
            <th>입금계좌설정</th>
            <th>실통장선택</th>
            <th>회원추천 가능여부</th>
            <th>추천코드(추천인변경시입력)</th>
          </tr>
          <tr>
            <td>
              <el-select size="mini" v-model="user.customerable" placeholder="고객센터 선택">
                <el-option :value="managerConst.YES" label="사용가능">사용가능</el-option>
                <el-option :value="managerConst.NOT" label="사용금지">사용금지</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="user.banktype" placeholder="입금계좌설정 선택">
                <el-option :value="managerConst.User.BANK_TYPE_NORMAL" label="수동답변">수동답변</el-option>
                <el-option :value="managerConst.User.BANK_TYPE_BANKCARDID" label="실통장">실통장</el-option>
                <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD" label="가상계좌 1">가상계좌 1
                </el-option>
                <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD_2" label="가상계좌 2">가상계좌 2
                </el-option>
                <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD_3" label="가상계좌 3">가상계좌 3
                </el-option>
                <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD_4" label="가상계좌 4">가상계좌 4
                </el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-if="user.banktype == managerConst.User.BANK_TYPE_BANKCARDID"
                         v-model="user.bankCardId" placeholder="실통장 선택">
                <el-option v-for="item in cctemplateList" :value="item.id" :label="item.title"
                           :key="item.id">{{ item.title }}
                </el-option>
              </el-select>
              <span
                  v-if="user.banktype == managerConst.User.BANK_TYPE_FACK_CARD && user.ccrtFackCard">   {{
                  user.ccrtFackCard.title
                }}</span>
              <span
                  v-if="user.banktype == managerConst.User.BANK_TYPE_FACK_CARD_2 && user.ccrtFackCard2">   {{
                  user.ccrtFackCard2.title
                }}</span>
              <span
                  v-if="user.banktype == managerConst.User.BANK_TYPE_FACK_CARD_3 && user.ccrtFackCard3">   {{
                  user.ccrtFackCard3.title
                }}</span>
              <span
                  v-if="user.banktype == managerConst.User.BANK_TYPE_FACK_CARD_4 && user.ccrtFackCard4">   {{
                  user.ccrtFackCard4.title
                }}</span>
            </td>
            <td>
              <el-select size="mini" v-model="user.beReferrer" placeholder="회원추천 선택">
                <el-option :value="managerConst.NOT" label="아니오">아니오</el-option>
                <el-option :value="managerConst.YES" label="예">예</el-option>
              </el-select>
            </td>
            <td>
              <el-input size="mini" v-model="rcode"></el-input>
            </td>
          </tr>
          <tr>
            <th>레벨별베팅롤링참여</th>
            <th>돌발이벤트참여</th>
            <th>출석이벤트참여</th>
            <th>코인충전가능여부</th>
            <th><!--돌발이벤트참여--></th>
            <th><!--출석이벤트참여--></th>
          </tr>
          <tr>
            <td>
              <el-select size="mini" v-model="user.betrollingable" placeholder="레벨별 베팅롤링">
                <el-option :value="managerConst.NOT" label="아니오">아니오</el-option>
                <el-option :value="managerConst.YES" label="예">예</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="user.eventcoffeable" placeholder="돌발이벤트참여">
                <el-option :value="managerConst.NOT" label="아니오">아니오</el-option>
                <el-option :value="managerConst.YES" label="예">예</el-option>
              </el-select>
            </td>
            <td>
              <el-select size="mini" v-model="user.eventculcheckable" placeholder="출석이벤트참여">
                <el-option :value="managerConst.NOT" label="아니오">아니오</el-option>
                <el-option :value="managerConst.YES" label="예">예</el-option>
              </el-select>
            </td>
            <td>
               <el-select size="mini" v-model="user.coinrechargeable" placeholder="코인충전참여가능여부">
                 <el-option :value="managerConst.YES" label="예">예</el-option>
                   <el-option :value="managerConst.NOT" label="아니오">아니오</el-option>

               </el-select>
            </td>
            <td>
              <!-- <el-select size="mini" v-model="user.event369able" placeholder="돌발이벤트참여">
                   <el-option :value="managerConst.NOT" label="아니오">아니오</el-option>
                   <el-option :value="managerConst.YES" label="예">예</el-option>
               </el-select>-->
            </td>


          </tr>
          <!--<tr>
              <th>10%쿠폰(보유: {{user.scoupon}}장)</th>
              <th>15%쿠폰(보유: {{user.gcoupon}}장)</th>
              <th>20%쿠폰(보유: {{user.dcoupon}}장)</th>
              <th>30%쿠폰(보유: {{user.vcoupon}}장)</th>
              <th>스티커 컬러</th>
          </tr>-->
          <!--<tr>
              <td>
                  <el-input size="mini" v-model="user.scupdate"></el-input>
              </td>
              <td>
                  <el-input size="mini" v-model="user.gcupdate"></el-input>
              </td>
              <td>
                  <el-input size="mini" v-model="user.dcupdate"></el-input>
              </td>
              <td>
                  <el-input size="mini" v-model="user.vcupdate"></el-input>
              </td>
              <td>
                  <el-color-picker size="mini" v-model="user.scolor"></el-color-picker>
              </td>
          </tr>-->

          <tr>
            <th>실버쿠폰(보유: {{ user.scoupon }}장)</th>
            <th>골드쿠폰(보유: {{ user.gcoupon }}장)</th>
            <th>다이아쿠폰(보유: {{ user.dcoupon }}장)</th>
            <th>스티커</th>
            <th>스티커 컬러</th>
          </tr>
          <tr>
            <td>
              <el-input size="mini" v-model="user.scupdate"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="user.gcupdate"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="user.dcupdate"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="user.sticker"></el-input>
            </td>
            <td>
              <el-color-picker size="mini" v-model="user.scolor"></el-color-picker>
            </td>
          </tr>
          <tr>
            <th>스포츠베팅상한</th>
            <th>인플레이베팅상한</th>
            <th>미니게임베팅상한</th>
            <th>입금상한</th>
            <th>출금상한</th>
          </tr>
          <tr>
            <td>
              <el-input size="mini" v-model="user.sportsbetmax"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="user.inplaybetmax"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="user.leisurebetmax"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="user.rechargemax"></el-input>
            </td>
            <td>
              <el-input size="mini" v-model="user.exchangemax"></el-input>
            </td>
          </tr>

          <tr>
            <th colspan="5">해당회원을 추천하고 가입한 회원</th>
          </tr>
          <tr>
            <td colspan="5">
                            <span v-for="item in user.subordinateUsers"
                                  style="display: inline-block;margin: 3px;padding: 2px;background-color: floralwhite;border-radius: 3px">
                                 {{ item.nickname }}
                            </span>
            </td>
          </tr>
        </table>
      </el-tab-pane>
      <el-tab-pane label="회원정보2" v-if="mode === managerConst.MODE_EDIT" name="userinfo2">
        <h5>메모</h5>
        <el-input v-if="null != user" type="textarea" v-model="user.record" rows="10"></el-input>
        <h5>미니게임 베팅제한 설정(체크시 해당 미니게임은 베팅이 불가 합니다)</h5>
        <div style="display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap">
          <el-checkbox-group v-model="user.leisureBetKindArr" size="small">
            <el-checkbox v-for="item in user.leisureKindConfigList" :label="item.idx"
                         style="margin: 2px 2px" border>{{ item.kindName }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-tab-pane>
      <el-tab-pane label="입출금내역" v-if="mode === managerConst.MODE_EDIT" name="applyHistory">
        <user-info-apply-comp :uid="applyUserId"></user-info-apply-comp>
      </el-tab-pane>
      <el-tab-pane label="머니포인트내역" v-if="mode === managerConst.MODE_EDIT" name="cashPointHistory"
                   style="max-height: 500px;overflow-y: scroll">
        <user-info-cash-point-log :uid="cashPointLogUserId"></user-info-cash-point-log>
      </el-tab-pane>
      <el-tab-pane label="Sports베팅내역" v-if="mode === managerConst.MODE_EDIT" name="sportsBet"
                   style="max-height: 700px;overflow-y: scroll">
        <user-info-sports-bet-comp :uid="sportsBetUserId"></user-info-sports-bet-comp>
      </el-tab-pane>
      <el-tab-pane label="Mini베팅내역" v-if="mode === managerConst.MODE_EDIT" name="minigameBet">
        <user-info-leisure-game-bet-comp :uid="miniBetUserId"></user-info-leisure-game-bet-comp>
      </el-tab-pane>
      <el-tab-pane label="Honor베팅내역" v-if="mode === managerConst.MODE_EDIT" name="honorcasionBet">
        <user-info-honor-casino-bet-comp :uid="honorcasionUserId"></user-info-honor-casino-bet-comp>
      </el-tab-pane>
      <!--            <el-tab-pane label="Ximax베팅내역" v-if="mode === managerConst.MODE_EDIT" name="ximaxcasionBet">-->
      <!--                <user-info-ximax-casino-bet-comp :uid="ximaxcasionUserId"></user-info-ximax-casino-bet-comp>-->
      <!--            </el-tab-pane>-->
      <el-tab-pane label="토큰베팅내역" v-if="mode === managerConst.MODE_EDIT" name="tgame365Bet">
        <user-info-tgame365-bet-comp :uid="tgame365UserId"></user-info-tgame365-bet-comp>
      </el-tab-pane>
      <!--            <el-tab-pane label="Reach카지노베팅내역" v-if="mode === managerConst.MODE_EDIT" name="reachcasionBet">-->
      <!--                <user-info-reach-casino-bet-comp :uid="reachcasionUserId"></user-info-reach-casino-bet-comp>-->
      <!--            </el-tab-pane>-->
      <el-tab-pane label="출석" v-if="mode === managerConst.MODE_EDIT" name="culcheck">
        <user-info-cul-check-comp :uid="culcheckUserId"></user-info-cul-check-comp>
      </el-tab-pane>
      <el-tab-pane label="로그인 로그" v-if="mode === managerConst.MODE_EDIT" name="loginLog">
        <user-info-login-log-comp :uid="loginLogUserId"></user-info-login-log-comp>
      </el-tab-pane>
      <el-tab-pane label="쪽지보내기" v-if="mode === managerConst.MODE_EDIT" name="sendMessage">
        <div style="display: flex;justify-content: flex-start;align-items: flex-start; flex-wrap: nowrap">
          <div style="width: 50%;display: inline-block;text-align: center">
            <div class="editor" style="padding: 5px">

              <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="editormode"
              />
              <Editor
                  style="height: 300px; overflow-y: hidden;"
                  :value="editorhtml"
                  :defaultConfig="editorConfig"
                  :mode="editormode"
                  @onCreated="onEditorCreated"
                  @onChange="onEditorChange"
              />
            </div>

            <el-button @click="sendMessageFromUserEdit" type="primary"
                       style="width: 100%!important;margin-top: 10px">쪽지 전송하기
            </el-button>
          </div>
          <div style="width: 50%;display: flex;justify-content: flex-start;align-items: flex-start;flex-wrap: wrap">
            <el-button type="success" size="mini" v-for="tmp in messageTempList"
                       style="width: 30%;margin: 5px" @click="setMessageContentByTemplate(tmp)">
              {{ tmp.title }}
            </el-button>
          </div>
        </div>
        <div style="max-height: 300px;overflow-y: scroll;margin-top: 10px">
          <h5>보낸쪽지 리스트</h5>
          <div class="customer_panel" v-for="item in messageList">
            <div style="width: 20%">
              {{ item.createTime|datef('yyyy-MM-DD HH:mm') }}
            </div>
            <div style="width: 15%">
              <span v-if="item.checked == managerConst.CHECKED">읽음</span>
              <span v-if="item.checked == managerConst.UNCHECKED" style="color: red">미확인</span>
            </div>
            <div style="width: 100%;cursor: pointer" v-if="item.type == managerConst.Message.TYPE_PRIVATE"
                 class="direct-chat-text" v-html="item.content"></div>
            <div style="width: 100%;cursor: pointer" v-if="item.type == managerConst.Message.TYPE_PUBLIC"
                 class="direct-chat-text" v-html="item.publicMessage.content"></div>
          </div>
        </div>


      </el-tab-pane>

      <el-tab-pane label="고객센터" v-if="mode === managerConst.MODE_EDIT" name="customerCenter"
                   style="max-height: 500px;overflow-y: scroll">
        <div class="customer_panel" v-for="item in customerList">
          <div style="width: 10%">
            {{ item.createTime|datef('yyyy-MM-DD HH:mm') }}
          </div>
          <div style="width: 10%">
            {{ item.operator }}
          </div>
          <div style="width: 10%;color: #ff4d4d" v-if="item.display == managerConst.DISABLE">
            [삭제되였습니다]
          </div>
          <div style="width: 100%;cursor: pointer" class="direct-chat-text" v-html="item.content"
               @click="replayCustomercenter(item.id)"></div>
          <div style="width: 100%;padding: 5px 10px">
            <el-button size="mini" type="primary"
                       v-if="item.status == managerConst.Customercenter.STATUS_UNCONFIRMED || item.status == managerConst.Customercenter.STATUS_CHECKED"
                       @click="replayCustomercenter(item.id)">
              <i class="fa fa-pencil"> 답변하기</i>
            </el-button>
            <el-button size="mini" type="danger"
                       v-if="item.status == managerConst.Customercenter.STATUS_UNCONFIRMED"
                       @click="changeCustomerStatus(item.id,managerConst.Customercenter.STATUS_CHECKED)">
              <i class="fa fa-bell-o"> 알람끄기</i>
            </el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div slot="footer" class="dialog-footer">
      <!--      <button style="background-color: #ffffff;float: left;height: 42px;border: 0 solid;color: #ffffff"-->
      <!--              @click="initUserData(user.id,'day')">당일 내역 초기화-->
      <!--      </button>-->
      <el-button @click="save()">저장하기</el-button>
      <el-button @click="outerVisible = false">취소</el-button>
    </div>
  </el-dialog>
</template>

<script>
import managerConst from "../../../common/administrator/managerConst";
import {getRankList} from "../../../network/manager/rankRequest";
import {getGroupList} from "../../../network/manager/groupRequest";
import {
  destoryUserSession,
  getUserById,
  getUserStatisticById, initUserData,
  saveUser, updateUser
} from "../../../network/manager/userRequest";
import {getCusTempList} from "../../../network/manager/customerTemplateRequest";
import UserInfoApplyComp from "../UserInfoApplyComp";
import UserInfoCashPointLog from "../UserInfoCashPointLog";
import UserInfoLoginLogComp from "../UserInfoLoginLogComp";
import UserInfoCulCheckComp from "../UserInfoCulCheckComp";
import {getMessageList, saveMessage} from "../../../network/manager/messageRequest";
import {
  gettHonorCasinoAccountBalance,
  subjecttHonorCasinoMemberCasinoCash
} from "../../../network/manager/honorCasinoRequest";
import UserInfoHonorCasinoBetComp from "../UserInfoHonorCasinoBetComp";
import UserInfoTgame365BetComp from "../UserInfoTgame365BetComp";
import {VueEditor} from "vue2-editor"
import {getCustomerCetnerList, updateCustomerCetner} from "../../../network/manager/customercenterRequest";
import Vue from "vue";
import CustomerReplayToast from "./CustomerReplayToast";
import UserInfoReachCasinoBetComp from "../UserInfoReachCasinoBetComp";
import {getAgentList} from "../../../network/manager/agentRequest";
import UserInfoXimaxCasinoBetComp from "../UserInfoXimaxCasinoBetComp";
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

Vue.prototype.$customerReplayEditToast = CustomerReplayToast
export default {
  name: "UserEditToast",
  components: {
    UserInfoXimaxCasinoBetComp,
    UserInfoReachCasinoBetComp,
    UserInfoTgame365BetComp,
    UserInfoHonorCasinoBetComp,
    UserInfoCulCheckComp,
    UserInfoLoginLogComp,
    UserInfoLeisureGameBetComp: () => import('../UserInfoLeisureGameBetComp.vue'),
    UserInfoSportsBetComp: () => import('../UserInfoSportsBetComp.vue'),
    UserInfoCashPointLog, UserInfoApplyComp, VueEditor,
    Editor, Toolbar
  },
  data() {
    return {
      title: '',
      userId: null, //
      applyUserId: null,
      cashPointLogUserId: null,
      sportsBetUserId: null,
      miniBetUserId: null,
      culcheckUserId: null,
      loginLogUserId: null,
      honorcasionUserId: null,
      ximaxcasionUserId: null,
      tgame365UserId: null,
      reachcasionUserId: null,
      closed: false,
      outerVisible: false,
      user: {'referreCode': {code: null}, honoruid: 0},
      managerConst: managerConst,
      mode: managerConst.MODE_SAVE, //save:등록 ,edit:수정,
      syear: [],
      smonth: [],
      sday: [],
      partnerList: [],
      agentList: [],
      rankList: [],
      groupList: [],
      messageTempList: [],
      activeName: 'userInfo',
      userStatistic: null,
      cctemplateList: [],
      message: {content: ''},
      messageList: [],
      customerList: [],
      rcode: null,

      editor: null,
      editorhtml: '',
      toolbarConfig: {},
      editorConfig: {placeholder: '내용을입력하세요...'},
      editormode: 'default', // or 'simple'


    }
  },
  methods: {
    close() {
      this.user = {'referreCode': {code: null}}
      this.userId = null
      this.closed = true
      this.outerVisible = false
    },
    destroyElement() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    },
    save() {
      if (this.mode === managerConst.MODE_SAVE) {
        saveUser(this.user).then(res => {
          if (res.data.success) {
            this.$message({
              showClose: false,
              message: '회원등록이 완료되였습니다',
              duration: 1500,
              type: 'success'
            });
            this.outerVisible = false
            this.$bus.$emit('userEdited')
          } else {
            this.$message({
              showClose: false,
              message: res.data.msg,
              duration: 1500,
              type: 'error'
            });
          }
        })
      }
      if (this.mode === managerConst.MODE_EDIT) {
        this.user.referreCode.code = this.rcode
        updateUser(this.user).then(res => {
          if (res.data.success) {
            this.$message({
              showClose: false,
              message: '회원정보 수정이 완료되였습니다',
              duration: 1500,
              type: 'success'
            });
            this.outerVisible = false
            this.$bus.$emit('userEdited')
          } else {
            this.$message({
              showClose: false,
              message: res.data.msg,
              duration: 1500,
              type: 'error'
            });
          }
        })
      }
    },
    handleClick(tab, event) {
      if (tab.name == 'applyHistory') {
        this.applyUserId = this.userId
      }
      if (tab.name == 'cashPointHistory') {
        this.cashPointLogUserId = this.userId
      }
      if (tab.name == 'sportsBet') {
        this.sportsBetUserId = this.userId
      }
      if (tab.name == 'minigameBet') {
        this.miniBetUserId = this.userId
      }
      if (tab.name == 'culcheck') {
        this.culcheckUserId = this.userId
      }
      if (tab.name == 'loginLog') {
        this.loginLogUserId = this.userId
      }
      if (tab.name == 'honorcasionBet') {
        this.honorcasionUserId = this.userId
      }
      if (tab.name == 'ximaxcasionBet') {
        this.ximaxcasionUserId = this.userId
      }
      if (tab.name == 'reachcasionBet') {
        this.reachcasionUserId = this.userId
      }
      if (tab.name == 'tgame365Bet') {
        this.tgame365UserId = this.userId
      }
      if (tab.name == 'sendMessage') {
        let record = {}
        record.userId = this.userId
        getMessageList(record, 1, 30).then(res => {
          this.messageList = res.data.data
        })
      }
      if (tab.name == 'customerCenter') {
        this.getCustomerList()
      }


    },
    removeUserSession(username) {
      destoryUserSession(username).then(res => {
        if (res.data.success) {
          this.$message({
            showClose: false,
            message: '로그아웃이 완료되였습니다',
            duration: 1500,
            type: 'success'
          });
        } else {
          this.$message({
            showClose: false,
            message: '로그아웃에 실패 하였습니다',
            duration: 1500,
            type: 'error'
          });
        }
      })
    },
    subjectCasionMoney(userId) {
      subjecttHonorCasinoMemberCasinoCash(userId).then(res => {
        if (res.data.success) {
          this.$message({
            showClose: false,
            message: '카지노 머니 강제전환이 완료되였습니다',
            duration: 1500,
            type: 'success'
          });
          this.getUserById()
        } else {
          this.$message({
            showClose: false,
            message: res.data.msg,
            duration: 1500,
            type: 'error'
          });
        }
      })
    },
    onEditorCreated(editor) {
      this.editor = Object.seal(editor)
    },
    onEditorChange(editor) {
      this.editorhtml = editor.getHtml()
    },
    setMessageContentByTemplate(temp) {
      this.editorhtml = temp.reply
    },
    sendMessageFromUserEdit() {
      this.message.content = this.editorhtml
      this.message.usernicknames = this.user.nickname
      this.message.message2who = managerConst.Message.MESSAGE2WHO_USER
      console.log(this.message)
      saveMessage(this.message).then(res => {
        if (res.data.success) {
          this.$message({
            showClose: false,
            message: '쪽지전송이 완료되였습니다',
            duration: 1500,
            type: 'success'
          });
          if (this.editorhtml != null) {
            this.editorhtml = ''
          }
        } else {
          this.$message({
            showClose: false,
            message: res.data.msg,
            duration: 1500,
            type: 'error'
          });
        }
      })
    },
    getUserById() {
      getUserById(this.userId).then(res => {
        this.user = res.data.data
        if (!this.user.referreCode) {
          this.user.referreCode = {'code': null}
        }
        if (this.user.honoruid) {
          try{
            gettHonorCasinoAccountBalance(this.user.id).then(res => {
              this.user.casinocash = res.data.data
            })
          }catch (e){
            console.log(e)
          }

        }

        this.title = '[' + this.user.nickname + '] 님 상세정보'
        getUserStatisticById(this.user.id).then(stres => {
          this.userStatistic = stres.data.data
        })
      })
    },
    replayCustomercenter(id) {
      this.$customerReplayEditToast({'customerId': id})
    },
    changeCustomerStatus(id, status) {
      let cc = {};
      cc.id = id;
      cc.status = status;
      updateCustomerCetner(cc).then(res => {
        this.getCustomerList()
      })
    },
    getCustomerList(day) {
      let record = {}
      record.userId = this.userId
      record.startDate = this.$moment().subtract(30,'days').format('yyyy-MM-DD');
      record.endDate = this.$moment().format('yyyy-MM-DD')
      getCustomerCetnerList(record, 1, 50).then(res => {
        this.customerList = res.data.data.replyed
      })
    },
    makeAgentList(list) {
      for (let i = 0; i < list.length; i++) {
        let agent = list[i];
        this.agentList.push(agent);
        if (agent.subAgentList && agent.subAgentList.length > 0) {
          this.makeAgentList(agent.subAgentList)
        }
      }
    },
    initUserData(userId, mode) {
      let cont = mode == 'day' ? '당일' : '*전체* '
      this.$swal({
        title: this.user.nickname + ' 님 ' + cont + ' 내역을 초기화 하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          initUserData(userId, mode).then(res => {
            if (res.data.success) {
              this.$swal({
                title: '초기화 성공',
                type: 'success',
                showCancelButton: false,
                showConfirmButton: true
              })
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })
    }
  },
  created() {
    this.user = {'referreCode': {code: null}}
    this.outerVisible = true
    getAgentList({'parent_id': 0}, 1, 10000).then(res => {
      let list = res.data.data;
      this.makeAgentList(list);
    })
    getRankList({'status': managerConst.ENABLE}, 1, 100).then(res => {
      this.rankList = res.data.data
    })
    getGroupList({'status': managerConst.ENABLE}, 1, 100).then(res => {
      this.groupList = res.data.data
    })
    getCusTempList({type: managerConst.CustomercenterTemplateType.TYPE_BANK_CARD}, 1, 20).then(res => {
      this.cctemplateList = res.data.data
    })
    getCusTempList({type: managerConst.CustomercenterTemplateType.TYPE_NORMAL}, 1, 100).then(res => {
      this.messageTempList = res.data.data
    })
    if (this.mode === managerConst.MODE_EDIT) {
      this.getUserById()
    }
    this.$bus.$on('customerReplayComplate', () => {
      this.getCustomerList()
    })

    let b = 1949
    for (let i = 1; i <= 71; i++) {
      let bb = b + i;
      this.syear.push(bb)
    }
    for (let i = 1; i <= 12; i++) {
      this.smonth.push(i)
    }
    for (let i = 1; i <= 31; i++) {
      this.sday.push(i)
    }

  },
  mounted() {

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy()
  },
  watch: {
    closed(newVal) {
      if (newVal) {
        this.user = null
        this.outerVisible = false
        this.destroyElement()
      }
    }
  },

}
</script>

<style scoped>
.ql-container .ql-editor {
  height: 300px !important;
}

.table100 td {
  padding: 5px;
  background-color: #cfcfd1 !important;
}

.table100 th {
  width: 20%;
}

.table100 .el-input__inner {
  width: 80% !important;
}

.table_userstatisc {
  width: 100%;
  margin-bottom: 5px;
}

.table_userstatisc th {
  width: 14%;
}

.table_userstatisc td {
  background-color: #e8e8e8 !important;
  width: 14%;
}

html.dark {
  --w-e-textarea-bg-color: #333;
  --w-e-textarea-color: #fff;
  /* ...其他... */
}

</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>